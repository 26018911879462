import React, { Fragment, useEffect } from 'react';
import YoastMeta from '@components/YoastMeta';
import { saveTagsToSessionStorage } from '@lib/searchQuery';
import '../../../theme/style.css';
import '../../../theme/leap/dist/assets/css/theme.min.css';


export default function Layout ({ children, pageContext }) {
  useEffect(() => {
    saveTagsToSessionStorage();
  }, []);

  return (
    <Fragment>
      {pageContext && pageContext.meta &&
        <YoastMeta meta={pageContext.meta} />
      }
      {children}
    </Fragment>
  );
}