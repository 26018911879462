import React from 'react';
import { navigate } from 'gatsby';
import Layout from './src/components/layouts/main';

export const onServiceWorkerUpdateReady = () => window.location.reload(true);

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onPreRouteUpdate = ({ location/*, prevLocation*/ }) => {
  if (location.pathname.startsWith('/en/blog/')) {
    let newPath = location.pathname.replace('/en/blog/', '/blog/');
    navigate(newPath, { replace: true });
  }
};