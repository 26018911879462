const moment = require('moment');
const { get } = require('lodash');

const isInternalUrl = (url) => /^\/(?!\/)/.test(url);

const getBlogPostDateSlug = (page) => {
  let date = get(page, 'blogPost.publishDate', null);
  return date ? moment(date, 'DD/MM/YYYY').format('YYYY/MM') : '';
};

const getPageSlug = (page) =>
  page && page.properties.slug !== '/' ? page.properties.slug : '';

const getLanguageSlug = (language) =>
  (language.slug || language.path || '');

const getPageSlugType = ({ type, page, parent }) => {
  switch (type) {
    case 'websites':
      return '/';
    case 'casestudies':
      return `/${parent.properties.slug}/`;
    case 'jobs':
      return `/${parent.properties.slug}/`;
    case 'blogposts':
      return `/${parent.properties.slug}/${getBlogPostDateSlug(page)}/`;
  }
};

const getPageUrlForType = ({ type = 'websites', language, page, parent }) =>
  getLanguageSlug(language) + getPageSlugType({ type, language, page, parent }) + getPageSlug(page);

const getBlogUrl = ({ language, page, parent }) =>
  getPageUrlForType({ type: 'blogposts', language, page, parent });

const getCaseStudyUrl = ({ language, page, parent }) =>
  getPageUrlForType({ type: 'casestudies', language, page, parent });

const getJobUrl = ({ language, page, parent }) =>
  getPageUrlForType({ type: 'jobs', language, page, parent });

const getTypeformUrl = ({ language, type, typeforms }) =>
  typeforms.find(typeform => type === typeform.type && language.locale === typeform.locale).url;

module.exports = {
  getPageUrlForType,
  getBlogUrl,
  getCaseStudyUrl,
  getJobUrl,
  getTypeformUrl,
  isInternalUrl
};