import React from 'react';
import { Helmet } from 'react-helmet';

export default function YoastMeta({ meta }) {
  const { title, tags, properties } = meta;
  return (
    <Helmet title={title} defer={false}>
      {tags.map(({ name, content }, i) =>
        <meta key={`tag-${i}`} name={name} content={content} />
      )}
      {properties.map(({ property, content }, i) =>
        <meta key={`property-${i}`} property={property} content={content} />
      )}
    </Helmet>
  );
}