import { compact, pickBy, forEach } from 'lodash';
import { getTypeformUrl } from '@lib/urls';

const tagWhitelist = ['utm_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'ref'];

export const parseQuery = (location) => {
  return location.search.length ?
    JSON.parse('{"' + location.search.substring(1).replace(/&/g, '","').replace(/=/g, '":"') + '"}', (key, value) => { return key === '' ? value : decodeURIComponent(value); })
    :
    {
      utm_source: 'direct'
    };
};

export const parseQueryTags = (location, tags) => {
  const urlSearchParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return pickBy(params, (value, key) => value && tags.includes(key));
};

export const getUtmArray = (location) => {
  const query = parseQuery(location);
  const utmNames = ['utm_campaign', 'utm_source', 'utm_medium', 'utm_content', 'utm_term'];
  const utmArray = utmNames.filter(utm => query[utm] && query[utm].length > 0 && query[utm] !== '_____');
  return utmArray.map(utm => ({ name: utm, value: query[utm] }));
};

export const getUtmTypeformUrl = ({ type, location, language, typeforms }) => {
  const url = getTypeformUrl({ type, language, typeforms });
  const utms = getUtmArray(location);
  if (utms.length < 1)
    return url;
  let utmUrl = url + '?';
  utms.forEach(({ name, value }, index) => {
    utmUrl += `${name}=${value}`;
    if (index < utms.length - 1)
      utmUrl += '&';
  });
  return utmUrl;
};

export const buildUtmUrl = (url, medium, content) =>
  `${url}?utm_campaign=frontpage&utm_source=website&utm_medium=${medium}&utm_content=${content}`;

export const getQueryValue = (query, name) =>
  query[name] && query[name].length > 0 && query[name] !== '_____' ? query[name] : null;

export const getQueryArrayValue = (query, names) => {
  const valueArray = compact(names.map(name => getQueryValue(query, name)));
  return valueArray.length > 0 ? valueArray.join(' ') : null;
};

export const assignQueryValue = (location, name, alias) => {
  const query = parseQuery(location);
  const value = Array.isArray(name) ? getQueryArrayValue(query, name) : getQueryValue(query, name);
  return value ? { [alias || name]: value } : {};
};

export const saveTagsToSessionStorage = () => {
  const isSSR = typeof window === 'undefined';
  if (isSSR) return null;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const tags = pickBy(params, (value, key) => value && tagWhitelist.includes(key));
  forEach(tags, (value, key) => sessionStorage.setItem(key, value));
};

export const getTagsFromSessionStorage = () => {
  const isSSR = typeof window === 'undefined';
  if (isSSR) return {};
  const tags = {};
  tagWhitelist.forEach(key => {
    let value = sessionStorage.getItem(key);
    if (value) tags[key] = value;
  });
  return tags;
};